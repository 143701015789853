enum TRAVEL_STYLE_SLUG {
  CRUISE = 'croisiere',
  FIT = 'voyage_sur_mesure',
  SAFARI = 'safari',
  EXTENSION = 'extension',
  ROADTRIP_AUTOTOUR = 'autotour',
  CIRCUIT_ACCOMPAGNE = 'circuit_accompagne',
  SEJOUR = 'sejour',
  EXCURSION = 'excursion'
}

export default TRAVEL_STYLE_SLUG;
