enum PRODUCT_LABEL {
  NONE = 'none',
  PROMOTIONS = 'promotions',
  DERNIERES_PLACES = 'dernieres_places',
  COUP_DE_COEUR = 'coup_de_coeur',
  NOTRE_COUP_DE_COEUR = 'notre_coup_de_coeur',
  NOUVEAUTE = 'nouveaute',
  BESTSELLER = 'best_seller',
  PARTENARIAT = 'partenariat',
  INEDIT = 'inedit',
  EVENEMENT = 'evenement',
  ECOTOURISME = 'ecotourisme',
  DEPART_GARANTI = 'depart_garanti',
  _2019_PRET_A_PARTIR = 'pret_a_partir_2019',
  _2020_PRET_A_PARTIR = 'pret_a_partir_2020',
  CIRCUIT_MAISON = 'circuit_maison',
  MILLESIME = 'millesime'
}

export default PRODUCT_LABEL;
