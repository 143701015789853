enum HEADER_MENUS_ENUM {
  DESTINATION = 'destinations',
  INSPIRATIONS = 'inspirations',
  WHEN_WHERE_TO_GO = 'ou-et-quand-partir',
  WHO_ARE_WE = 'whoAreWe',
  CULTURE_AND_TRAVEL = 'cultureAndTravel',
  SPECIAL_OFFERS = 'specialOffers',
  CONTACT_REFERENT = 'contactReferent',
  TRAVELS = 'travels',
  REFS_AND_TESTIMONIALS = 'refsAndTestimonials'
}

export default HEADER_MENUS_ENUM;
