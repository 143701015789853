enum WpContinent {
  AFRIQUE = 'afrique',
  AMERIQUE_NORD = 'amerique',
  AMERIQUE_LATINE = 'amerique-latine',
  ASIE_NORD = 'chine',
  ASIE_SUD = 'asie',
  EMPTY = 'empty',
  INDE = 'inde',
  EUROPE = 'europe',
  LES_MAISONS_DU_VOYAGE = 'les-maisons-du-voyage',
  OCEANIE = 'oceanie',
  ORIENT = 'orient'
}

export default WpContinent;
