enum INSPIRATION_CATEGORY_SLUG {
  SPA = 'bien-etre-detente-et-spa',
  CIRCUIT_ACCOMPAGNE = 'circuit-accompagne',
  CROISIERES = 'croisieres',
  ECOTOURISME = 'ecotourisme',
  FETES_ET_FESTIVALS = 'fetes-et-festivals',
  HORS_DES_SENTIERS_BATTUS = 'hors-des-sentiers-battus',
  ROADTRIP_AUTOTOUR = 'roadtrip-autotour',
  SAFARI = 'safari',
  TREK_ET_RANDONNEE = 'trek-randonnee',
  VOYAGE_CULTUREL = 'voyage-culturel',
  VOYAGE_DE_LUXE = 'voyage-de-luxe',
  VOYAGE_DE_NOCES = 'voyage-de-noces',
  VOYAGE_EN_FAMILLE = 'voyage-en-famille',
  VOYAGE_EN_TRAIN = 'voyage-en-train',
  WEEK_END = 'week-end'
}

export default INSPIRATION_CATEGORY_SLUG;
