enum HouseCode {
  AFRICA_AND_INDIAN_OCEAN = 'MDF',
  NORTH_AMERICA = 'MDE',
  SOUTH_AMERICA = 'MDA',
  ASIA = 'MDI',
  CHINA = 'MDC',
  EUROPE = 'DDI',
  INDIA = 'MDN',
  AUSTRALIA_AND_PACIFIC_ISLANDS = 'MDP',
  EAST = 'MDO'
}

export default HouseCode;
