enum Continent {
  ASIE = 'asie',
  EUROPE = 'europe',
  AFRIQUE = 'afrique',
  OCEANIE = 'oceania',
  AMERIQUES = 'ameriques',
  ORIENT = 'orient',
  ILES = 'les-iles'
}

export default Continent;
