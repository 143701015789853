enum PROPOSITION_LEFT_MENU_ENUM {
  ESSENTIAL = 'essentiel',
  ITINERARY = 'itineraire',
  PRICE = 'prix',
  ACCOMODATION = 'hebergement',
  FORMALITIES = 'formalites',
  EXPERT = 'expert'
}

export default PROPOSITION_LEFT_MENU_ENUM;
