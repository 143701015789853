enum GeographicZoneSlug {
  ISLANDS = 'les-iles',
  EUROPE = 'europe',
  EAST = 'orient',
  AFRICA_AND_INDIAN_OCEAN = 'afrique',
  ASIA = 'asie',
  AUSTRALIA_AND_PACIFIC_ISLANDS = 'oceanie',
  AMERICA = 'amerique'
}

export default GeographicZoneSlug;
